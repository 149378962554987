import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { compose, withState } from 'recompose';
import { SCREEN_SIZE, STYLES } from '../../../../style';

const StyledFAQListItem = styled.div`
  margin-bottom: 1rem;
`;

const Question = styled.div`
  font-size: ${STYLES.FONT_SIZE_LSM};
  margin-bottom: 1rem;
  ${SCREEN_SIZE.LG} {
    cursor: pointer;
    .no-touch &:hover {
      opacity: 0.7;
    }
  }
`;

const Answer = styled.div`
  font-size: ${STYLES.FONT_SIZE_SM};
  color: ${STYLES.COLOR_DOVE_GRAY};
  max-width: 50rem;
  line-height: ${STYLES.LINE_HEIGHT_MD};
`;

const FAQListItem = ({
  questionNo,
  question,
  answer,
  isShow,
  setIsShow,
}) => (
  <StyledFAQListItem>
    <Question
      onClick={() => setIsShow(!isShow)}
    >
      {questionNo}<span>.</span> {question}
    </Question>
    { isShow && <Answer>{answer}</Answer> }
  </StyledFAQListItem>
);

FAQListItem.propTypes = {
  questionNo: PropTypes.number.isRequired,
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  isShow: PropTypes.bool.isRequired,
  setIsShow: PropTypes.func.isRequired,
};

const EnhancedFAQListItem = compose(
  withState('isShow', 'setIsShow', false),
)(FAQListItem);

export { EnhancedFAQListItem as FAQListItem };