import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FAQList } from './FAQList';
import { Centralized } from '../../shared/layout';
import { SCREEN_SIZE } from '../../../style';
import { SideNavigation } from '../../shared/SideNavigation';
import { Section } from '../../shared/Section';

const StyledFAQListContainer = styled(Section)`
  ${SCREEN_SIZE.LG} {
    display: flex;
    align-items: flex-start;
  }
`;

const items = {
  general: [
    {
      question: 'How is it that Hand Carry is faster and cheaper?',
      answer: 'Because we utilise the otherwise wasted energy of commuters constantly travelling. Our members are happy to pick up and drop off parcels for a small fee since they’re already going the same way. We have little to no overheads, with almost all the profits going back into the community',
    },
    {
      question: 'How is Hand Carry better for the environment?',
      answer: 'Because our parcels travel direct from the business to the consumer, there’s no unnecessary trips back and fourth to the post office, or the depot, and the parcels won’t have to travel all around the city waiting for their turn to be delivered. We’re using the momentum of transportation thats already moving regardless of Hand Carry.',
    },
    {
      question: 'Is Hand Carry safe?',
      answer: 'We vet each and every member we have, and they have to provide comprehensive identification before they can deliver. Our parcels are also insured for a value of up tp $250 USD and all our members are also covered for any work place accidents.',
    },
    {
      question: 'Does Hand Carry ship overseas?',
      answer: 'Hand Carry does not offer overseas delivery at the moment. We specialise in agile last mile deliveries inside and between cities.',
    },
  ],
  commuters: [
    {
      question: 'Is Hand Carry a job?',
      answer: 'Hand Carry is not your job unless you want it to be. It’s totally up to you, when and how earn is completely up to you.',
    },
    {
      question: 'How much can I earn with Hand Carry?',
      answer: 'With Hand Carry, we pay based on the parcel size, not the distance, since you’re already going near where the parcel needs to be delivered. So the more parcels you deliver, the more you can earn. When you increase your level of membership with Hand Carry you can take more than 1 parcel at a time, making it even more efficient.',
    },
    {
      question: 'Can anyone do it?',
      answer: 'As long as you have a smartphone. Whether you’re a student, employee, retired or anything in between. You can help make a difference.',
    },
    {
      question: 'Does it matter how I deliver the parcel?',
      answer: 'Since we match you with the parcels with the direction that you’re already heading, it doesn’t matter how you choose to deliver the parcel. You could be walking, catching a train, or driving, we’re not using any more energy than that would have been used already.',
    },
    {
      question: 'Do I get paid more if I deliver further rather than closer?',
      answer: 'Hand Carry pays based on parcel class, not distance. you can see our table of fees payable here.',
    },
    {
      question: 'How do I increase my membership level?',
      answer: 'The more deliveries you do, the more XP points you collect. You can see our membership table here.',
    },
    {
      question: 'Can I cancel a job after I’ve accepted it?',
      answer: 'Sometimes things change, we get that. You cancel the delivery before you pick it up. Learn more about our cancellation policies here.',
    },
    {
      question: 'What if I’ve picked it up and I can no longer deliver the parcel?',
      answer: 'You can contact us immediately from within the app and we’ll send someone out to pick up the parcel from you and deliver it.',
    },
  ],
  business: [
    {
      question: 'Are there any sign up costs to use Hand Carry or contracts that need to be signed?',
      answer: 'Absolutely not. Simply download the app, register with a few basic details and use Hand Carry when it works for you. We’re aware Hand Carry won’t work for each and every parcel you send, but when possible, using Hand Carry will benefit everyone.',
    },
    {
      question: 'Where do you deliver to and from?',
      answer: 'Hand Carry members come to your door, so all you have to do is prepare the parcel as you normally would and we’ll come and pick it up. Currently Hand Carry operates in the Tokyo greater area only, but we are constantly growing and looking to eventually cover all of Japan. See our coverage zone here.',
    },
    {
      question: 'How much does Hand Carry Cost?',
      answer: 'We’re about 30% cheaper on average than all our competitors that offer standard delivery. If you were to compare us to Same day deliveries we would be about 60% cheaper. You can see our fee table here.',
    },
    {
      question: 'Why should I use Hand Carry instead of other logistics providers?',
      answer: 'Hand Carry is beneficial to all parties involved, from the merchant, to the consumer, and of course the environment. Learn more about the benefits of Hand Carry here.',
    },
    {
      question: 'Is there any incentive to sign up now?',
      answer: 'Even though there’s no contracts or minimum spends, we have a limited amount of foundation memberships available. A foundation membership give you access to free shipping, as well as gives you access to the highest reward tier immediately. As a foundation member, it simply means you were supporting Hand Carry from the start. You can see our business memberships here.',
    },
  ],
};


const links = [
  { type: 'general', text: 'General', url: '/faq/general' },
  { type: 'business', text: 'Business', url: '/faq/business' },
  { type: 'commuters', text: 'Commuters', url: '/faq/commuters' },
];

const FAQListContainer = ({
  type,
}) => {
  const activeLink = links.find(link => link.type === type);
  if (!activeLink) { return null; }
  return (
    <Centralized>
      <StyledFAQListContainer>
        <SideNavigation
          links={links}
          activeLink={activeLink.url}
        />
        <FAQList
          items={items[type]}
        />
      </StyledFAQListContainer>
    </Centralized>
  );
};

FAQListContainer.propTypes = {
  type: PropTypes.string.isRequired,
};

export { FAQListContainer };