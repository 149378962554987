import React from 'react';
import styled from 'styled-components';
import {
  Banner, BannerContent, BannerDescription, BannerContentItem, BannerTitle,
} from '../../shared/Banner';
import { PageImage } from '../../shared/PageImage';
import FAQBannerIconSource from './images/faqBannerIcon.png';
import { SCREEN_SIZE, STYLES } from '../../../style';

const StyledFAQBanner = styled(Banner)``;

const BannerImage = styled(PageImage)`
  max-width: 100%;
  ${SCREEN_SIZE.LG} {
    max-width: 320px;
  }
`;

const FAQBanner = () => (
  <StyledFAQBanner color={STYLES.PAGE_FAQ_BANNER_COLOR}>
    <BannerContent>
      <BannerContentItem>
        <BannerImage src={FAQBannerIconSource} />
      </BannerContentItem>
      <BannerContentItem>
        <BannerTitle>Frequently asked questions</BannerTitle>
        <BannerDescription>
          Let’s see if we can answer some of your questions.
        </BannerDescription>
        <BannerDescription>
          We’ve compiled this list of questions based on our own
          research. But if you can’t find what you’re after. Please
          need to have it unfortunately.
        </BannerDescription>
      </BannerContentItem>
    </BannerContent>
  </StyledFAQBanner>
);

export { FAQBanner };