import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FAQListItem } from './FAQListItem';
import { SCREEN_SIZE } from '../../../../style';

const StyledFAQList = styled.div`
  margin: 2rem 0;
  ${SCREEN_SIZE.LG} {
    margin: 0 0 2rem 0;
  }
`;

const FAQList = ({
  items,
}) => (
  <StyledFAQList>
    { items.map(({ question, answer }, index) => <FAQListItem key={`${index}-${question}`} questionNo={index + 1} question={question} answer={answer} />) }
  </StyledFAQList>
);

FAQList.propTypes = {
  // eslint-disable-next-line
  items: PropTypes.array.isRequired,
};

export { FAQList };